import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';


/**
 * Request Dashboard Statistics
 * @param {Function} handleDashboardStatisticsSuccessCallback
 * @param {Function} handleDashboardStatisticsFailedCallback
 * @returns
 */

export const requestDashboardStatistics =
  (
    handleDashboardStatisticsSuccessCallback = null,
    handleDashboardStatisticsFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getDashboardStatisticsRequest' });

    const requestData = {
      url: API_URL.dashboard.DASHBOARD_STATISTICS,
      method: 'GET',
    };
    const successCallback = (response) => {
        handleDashboardStatisticsSuccessCallback?.(response);
      dispatch({ type: 'getDashboardStatisticsRequestSuccess', payload: response.data });
    };
    const errorCallback = (error) => {
      handleDashboardStatisticsFailedCallback?.(error);
      dispatch({ type: 'getDashboardStatisticsRequestFailed', payload: error });
    };
    http_request(requestData, successCallback, errorCallback);
  };