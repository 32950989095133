import { createReducer } from '@reduxjs/toolkit';

const advisorProfileSettingInitialState = {
  //Create New Advisor Profile Setting
  isSaveAdvisorProfileSettingLoading: false,
  saveAdvisorProfileSettingSuccessData: {},
  saveAdvisorProfileSettingFailedData: {},

  //get Advisor Profile Settings
  isGetAdvisorProfileSettingsLoading: false,
  advisorProfileSettingsSuccessData: {},
  advisorProfileSettingsFailedData: {},

  //update Advisor Profile Setting
  isUpdateAdvisorProfileSettingLoading: false,
  updateAdvisorProfileSettingSuccessData: {},
  updateAdvisorProfileSettingFailedData: {},

  //upload Profile Picture
  isUploadProfilePictureSettingRequestLoading: false,
  uploadProfilePictureSettingRequestSuccessData: {},
  uploadProfilePictureSettingRequestFailedData: {},

  //get uploadable signed url
  isUploadProfilePictureSignedURLRequestLoading: false,
  uploadProfilePictureSignedURLRequestSuccessData: {},
  uploadProfilePictureSignedURLRequestFailedData: {},

  //change my profile password
  isMyProfilePasswordChangeRequestLoading: false,
  myProfilePasswordChangeRequestSuccessData: {},
  myProfilePasswordChangeRequestFailedData: {},
};
/**
 * Advisor Profile Setting Reducer
 */
export const advisorProfileSettingsReducer = createReducer(advisorProfileSettingInitialState, {
  // save Advisor Profile Setting
  postSaveAdvisorProfileSettingRequest: (state) => {
    state.isSaveAdvisorProfileSettingLoading = true;
    state.saveAdvisorProfileSettingSuccessData = {};
    state.saveAdvisorProfileSettingFailedData = {};
  },
  postSaveAdvisorProfileSettingRequestSuccess: (state, action) => {
    state.isSaveAdvisorProfileSettingLoading = false;
    state.saveAdvisorProfileSettingSuccessData = action?.payload;
    state.saveAdvisorProfileSettingFailedData = {};
  },
  postSaveAdvisorProfileSettingRequestFailed: (state, action) => {
    state.isSaveAdvisorProfileSettingLoading = false;
    state.saveAdvisorProfileSettingSuccessData = {};
    state.saveAdvisorProfileSettingFailedData = action?.payload;
  },

  //get Advisor Profile Settings
  getAdvisorProfileSettingRequest: (state) => {
    state.isGetAdvisorProfileSettingsLoading = true;
    state.advisorProfileSettingsSuccessData = {};
    state.advisorProfileSettingsFailedData = {};
  },
  getAdvisorProfileSettingRequestSuccess: (state, action) => {
    state.isGetAdvisorProfileSettingsLoading = false;
    state.advisorProfileSettingsSuccessData = action.payload;
    state.advisorProfileSettingsFailedData = {};
  },
  getAdvisorProfileSettingRequestFailed: (state, action) => {
    state.isGetAdvisorProfileSettingsLoading = false;
    state.advisorProfileSettingsSuccessData = {};
    state.advisorProfileSettingsFailedData = action.payload;
  },
  resetGetAdvisorProfileSettingRequest: (state) => {
    state.isGetAdvisorProfileSettingsLoading = false;
    state.advisorProfileSettingsSuccessData = {};
    state.advisorProfileSettingsFailedData = {};
  },

  //update Advisor Profile Setting
  updateAdvisorProfileSettingRequest: (state) => {
    state.isUpdateAdvisorProfileSettingLoading = true;
    state.updateAdvisorProfileSettingSuccessData = {};
    state.updateAdvisorProfileSettingFailedData = {};
  },
  updateAdvisorProfileSettingRequestSuccess: (state, action) => {
    state.isUpdateAdvisorProfileSettingLoading = false;
    state.updateAdvisorProfileSettingSuccessData = action.payload;
    state.updateAdvisorProfileSettingFailedData = {};
  },
  updateAdvisorProfileSettingRequestFailed: (state, action) => {
    state.isUpdateAdvisorProfileSettingLoading = false;
    state.updateAdvisorProfileSettingSuccessData = {};
    state.updateAdvisorProfileSettingFailedData = action.payload;
  },

  //upload advisor profile picture
  uploadUserProfilePictureSettingRequest: (state) => {
    state.isUploadProfilePictureSettingRequestLoading = true;
    state.uploadProfilePictureSettingRequestSuccessData = {};
    state.uploadProfilePictureSettingRequestFailedData = {};
  },
  uploadUserProfilePictureSettingRequestSuccess: (state, action) => {
    state.isUploadProfilePictureSettingRequestLoading = false;
    state.uploadProfilePictureSettingRequestSuccessData = action.payload;
    state.uploadProfilePictureSettingRequestFailedData = {};
  },
  uploadUserProfilePictureSettingRequestFailed: (state, action) => {
    state.isUploadProfilePictureSettingRequestLoading = false;
    state.uploadProfilePictureSettingRequestSuccessData = {};
    state.uploadProfilePictureSettingRequestFailedData = action.payload;
  },

  //profile picture Upload Signed URL
  uploadProfilePictureSignedURLRequest: (state) => {
    state.isUploadProfilePictureSignedURLRequestLoading = true;
    state.uploadProfilePictureSignedURLRequestSuccessData = {};
    state.uploadProfilePictureSignedURLRequestFailedData = {};
  },
  uploadProfilePictureSignedURLRequestSuccess: (state, action) => {
    state.isUploadProfilePictureSignedURLRequestLoading = false;
    state.uploadProfilePictureSignedURLRequestSuccessData = action.payload;
    state.uploadProfilePictureSignedURLRequestFailedData = {};
  },
  uploadProfilePictureSignedURLRequestFailed: (state, action) => {
    state.isUploadProfilePictureSignedURLRequestLoading = false;
    state.uploadProfilePictureSignedURLRequestSuccessData = {};
    state.uploadProfilePictureSignedURLRequestFailedData = action.payload;
  },

  //my profile password change
  myProfilePasswordChangeRequest: (state) => {
    state.isMyProfilePasswordChangeRequestLoading = true;
    state.myProfilePasswordChangeRequestSuccessData = {};
    state.myProfilePasswordChangeRequestFailedData = {};
  },
  myProfilePasswordChangeRequestSuccess: (state, action) => {
    state.isMyProfilePasswordChangeRequestLoading = false;
    state.myProfilePasswordChangeRequestSuccessData = action.payload;
    state.myProfilePasswordChangeRequestFailedData = {};
  },
  myProfilePasswordChangeRequestFailed: (state, action) => {
    state.isMyProfilePasswordChangeRequestLoading = false;
    state.myProfilePasswordChangeRequestSuccessData = {};
    state.myProfilePasswordChangeRequestFailedData = action.payload;
  },
});
