import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
// import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
// import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import { requestCompositeData } from 'redux/Actions/compositesActions';
import { requestClientDetail } from 'redux/Actions/clientsAction';
import ReAuthenticateModal from 'modal/reAuthentication/ReAuthenticateModal';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('md')]: {
        marginLeft: -(drawerWidth - 20),
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px',
        marginRight: '10px',
      },
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

  /**
   * Redux Integration
   */
  const dispatch = useDispatch();
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  //composite store
  const compositeDetails = useSelector((state) => state.compositeDataReducer);
  //is logged out
  const isLogout = useSelector((state) => state.authenticationReducer?.isLogout);
  const loginLoading = useSelector((state) => state.authenticationReducer?.loginLoading);
  const loginUser = useSelector((state) => state.authenticationReducer?.loginData?.user);
  const isTokenAvailable = useSelector((state) => !!state.authenticationReducer?.loginData?.token);
  const clientReducer = useSelector((state) => state.clientReducer);
  /**
   * Toggle Drawer
   */
  const handleLeftDrawerToggle = () => {
    // dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    dispatch({ type: 'customization_SET_MENU', opened: !leftDrawerOpened });
  };
  /**
   * Effect on layout width change[md]
   */
  useEffect(() => {
    // dispatch({ type: SET_MENU, opened: !matchDownMd });
    dispatch({ type: 'customization_SET_MENU', opened: !matchDownMd });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  /**
   * Effect on mount
   */
  useEffect(() => {
    if (
      typeof compositeDetails?.compositeData === 'undefined' ||
      (typeof compositeDetails?.compositeData === 'object' &&
        !Object.keys(compositeDetails.compositeData).length)
    ) {
      dispatch(requestCompositeData());
    }

    if (
      loginUser?._id && (
        !clientReducer?.selectedClientDetail?._id ||
        clientReducer?.selectedClientDetail?._id !== loginUser?._id
      )
    ) {
      const clientDetailRequestData = {
        clientId: loginUser?._id,
      };
      //Login Client Detail
      dispatch(requestClientDetail(clientDetailRequestData));
    }
  }, [loginUser?._id]);
  return (
    <Box sx={{ display: 'flex' }}>
      {(isLogout || (!isTokenAvailable && !loginLoading)) && <Navigate to="/" replace={true} />}
      <ReAuthenticateModal />
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgColor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Outlet />
      </Main>
      {/* <Customization /> */}
    </Box>
  );
};

export default MainLayout;
