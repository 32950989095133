import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //All
  eventFullDataLoading: false,
  eventFullData: {},
  eventFullDataError: null,
  //Single
  eventDataLoading: false,
  eventData: null,
  eventDataError: null,
  // Create
  createEventLoading: false,
  createEventData: null,
  createEventError: null,
  // Update
  updateEventLoading: false,
  updateEventData: null,
  updateEventError: null,
  // Delete
  deleteEventLoading: false,
  deleteEventData: null,
  deleteEventError: null,
};

export const eventReducer = createReducer(initialState, {
  // Get All Events
  getAllEventDataRequest: (state) => {
    state.eventFullDataLoading = true;
    state.eventFullData = {};
    state.eventFullDataError = null;
  },
  getFullEventRequestSuccess: (state, action) => {
    state.eventFullDataLoading = false;
    state.eventFullData = action.payload;
    state.eventFullDataError = null;
  },
  getAllEventDataRequestFailed: (state, action) => {
    state.eventFullDataLoading = false;
    state.eventFullData = {};
    state.eventFullDataError = action.payload;
  },
  //Get Event
  getEventDataRequest: (state) => {
    state.eventDataLoading = true;
    state.eventData = null;
    state.eventDataError = null;
  },
  getEventDataRequestSuccess: (state, action) => {
    state.eventDataLoading = false;
    state.eventData = action.payload;
    state.eventDataError = null;
  },
  getEventDataRequestFaild: (state, action) => {
    state.eventDataLoading = false;
    state.eventData = null;
    state.eventDataError = action.payload;
  },
  // Create Event
  createEventRequest: (state) => {
    state.createEventLoading = true;
    state.createEventData = null;
    state.createEventError = null;
  },
  createEventRequestSuccess: (state, action) => {
    state.createEventLoading = false;
    state.createEventData = action.payload;
    state.createEventError = null;
  },
  createEventRequestFailed: (state, action) => {
    state.createEventLoading = false;
    state.createEventData = null;
    state.createEventError = action.payload;
  },
  // Update Event
  updateEventRequest: (state) => {
    state.updateEventLoading = true;
    state.updateEventData = null;
    state.updateEventError = null;
  },
  updateEventRequestSuccess: (state, action) => {
    state.updateEventLoading = false;
    state.updateEventData = action.payload;
    state.updateEventError = null;
  },
  updateEventRequestFailed: (state, action) => {
    state.updateEventLoading = false;
    state.updateEventData = null;
    state.updateEventError = action.payload;
  },
  // Delete Event
  deleteEventRequest: (state) => {
    state.deleteEventLoading = true;
    state.deleteEventData = null;
    state.deleteEventError = null;
  },
  deleteEventRequestSuccess: (state, action) => {
    state.deleteEventLoading = false;
    state.deleteEventData = action.payload;
    state.deleteEventError = null;
  },
  deleteEventRequestFailed: (state, action) => {
    state.deleteEventLoading = false;
    state.deleteEventData = null;
    state.deleteEventError = action.payload;
  },
});
