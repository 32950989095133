// assets
import { ReactComponent as AdvisorDetailsIcon } from 'assets/images/icons/briefcase.svg';
import { ReactComponent as DocumentsAndFilesIcon } from 'assets/images/icons/folder.svg';
import { ReactComponent as FinancialInfoIcon } from 'assets/images/icons/financial_info.svg';
import { ReactComponent as MyProfileIcon } from 'assets/images/icons/user.svg';
import { ReactComponent as IconSettings } from 'assets/images/icons/settings.svg';
import { ReactComponent as IconPayments } from 'assets/images/icons/payments-outline.svg';
import { ReactComponent as IconEvent } from 'assets/images/icons/event.svg';
import { ReactComponent as ProfileIcon } from 'assets/images/icons/profile.svg';
import { ReactComponent as QuestionnaireOutlinedIcon } from 'assets/images/icons/questionnaire-outlined.svg';

import { ReactComponent as IconMember } from 'assets/images/icons/member.svg';
import { ReactComponent as IconClient } from 'assets/images/icons/client.svg';
import { ReactComponent as IconTask } from 'assets/images/icons/task.svg';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Groups2Icon from '@mui/icons-material/Groups2';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PersonIcon from '@mui/icons-material/Person';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
// constant
const icons = {
  AdvisorDetailsIcon,
  DocumentsAndFilesIcon,
  FinancialInfoIcon,
  MyProfileIcon,
  IconSettings,
  IconPayments,
  QuestionnaireOutlinedIcon,

  IconClient,
  IconMember,
  IconTask,
  IconEvent,
  ProfileIcon,
  CalendarMonthIcon,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: 'utilities',
  title: null,
  type: 'group',
  children: [
    {
      id: 'guest',
      title: 'Guests',
      type: 'item',
      url: '/main/guests',
      icon: Groups2Icon,
      breadcrumbs: false,
    },
    {
      id: 'teamMember',
      title: 'Team members',
      type: 'item',
      url: '/main/team-members',
      icon: PeopleAltIcon,
      breadcrumbs: false,
    },
    {
      id: 'teamMember',
      title: 'Events',
      type: 'item',
      url: '/main/events',
      icon: CalendarMonthIcon,
      iconViewBox: '0 0 30 30',
      breadcrumbs: false,
    },
    {
      id: 'teamMember',
      title: 'Tasks',
      type: 'item',
      url: '/main/task',
      icon: PlaylistAddCheckIcon,
      breadcrumbs: false,
    },
    {
      id: 'cashflow',
      title: 'Cashflow',
      type: 'item',
      url: '/main/cashflow',
      icon: CurrencyExchangeIcon,
      breadcrumbs: false,
    },
    {
      id: 'profile',
      title: 'My Profile',
      type: 'item',
      url: '/main/my-profile',
      icon: PersonIcon,
      breadcrumbs: false,
    },
  ],
};

export default utilities;
