import EventIcon from '@mui/icons-material/Event';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { format, isValid } from 'date-fns';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { requestAllEvents } from 'redux/Actions/eventAction';
import MainCard from 'ui-component/cards/MainCard';
import NoData from 'ui-component/NoData';
import { Grid } from '@mui/material';

export default function Timeline() {
  const dispatch = useDispatch();
  const { eventFullData } = useSelector((state) => state.eventReducer);

  useEffect(() => {
    dispatch(requestAllEvents());
  }, [dispatch]);

  const navigate = useNavigate();

  const activeFinancialPosition = useSelector(
    (state) => state.authenticationReducer?.loginData?.activeFinancialPosition
  );

  const state = {
    financialPositionId: activeFinancialPosition,
    smsf: '',
  };

  const eventList = Array.isArray(eventFullData) ? eventFullData.slice(0, 6) : [];
  console.log('5555555555555---eventList---55555555555', eventList);

  return (
    <MainCard
      sx={{
        cursor: 'pointer',
        borderRadius: '16px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(135deg, #f5f5f5, #ffffff)',
        transition: 'all 0.3s ease',
        padding: '20px',
        border: '1px solid #e0e0e0',
        overflow: 'hidden',
        ':hover': {
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
          transform: 'scale(1.02)',
        },
      }}
      onClick={() => navigate('/main/events', { state: state })}
    >
      <Typography
        sx={(theme) => ({
          color: theme.palette.blue?.[600],
          fontWeight: theme.typography.fontWeightBold,
          fontSize: '1.2rem',
          mb: 2,
        })}
      >
        Event Schedule
      </Typography>
      <nav aria-label="main mailbox folders">
        {eventList.length > 0 ? (
          <List
            sx={{
              padding: 0,
              borderRadius: '12px',
              transition: 'all 0.3s ease',
            }}
          >
            {eventList.map((item, i) => (
              <ListItem
                disablePadding
                key={i}
                sx={{
                  marginBottom: '6px', // Space between ListItems
                }}
              >
                <ListItemButton
                  sx={(theme) => ({
                    borderRadius: '8px',
                    marginBottom: '8px', // Space between list items
                    padding: '10px 16px', // Padding inside the button
                    transition: 'background-color 0.3s',
                    background: 'linear-gradient(135deg, #f5f5f5, #f0f0f0)',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Soft shadow for each item
                    display: 'flex', // Enable flexbox for alignment
                    justifyContent: 'space-between', // Space between event name and date
                    alignItems: 'center', // Center the items vertically
                    '&:hover': {
                      backgroundColor: theme.palette.white,
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Elevate shadow on hover
                    },
                  })}
                >
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>

                  {/* Event Name on the Left Side */}
                  <ListItemText
                    primary={`${item.eventName.slice(0, 10)}...`}
                    primaryTypographyProps={{
                      sx: {
                        color: 'black !important', // Dark gray color for text
                        fontSize: '1rem', // Adjust font size
                      },
                    }}
                    sx={{ flex: 1 }} // Ensures event name takes available space
                  />

                  {/* Event Start Date on the Right Side */}
                  <ListItemText
                    primary={
                      item.eventStartDate && isValid(new Date(item.eventStartDate))
                        ? format(new Date(item.eventStartDate), 'yyyy-MM-dd')
                        : '-'
                    }
                    primaryTypographyProps={{
                      sx: {
                        color: 'black !important', // Dark gray color for text
                        fontSize: '1rem', // Adjust font size
                      },
                    }}
                    sx={{ textAlign: 'right' }} // Align the date to the right side
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Grid width="100%" height={300}>
            <NoData height={'12rem'} />
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              sx={{ padding: '20px', paddingTop: '80px' }} // Adjust '40px' to your desired top padding value
            >
              No Data, Add New Event here !
            </Typography>
          </Grid>
        )}
      </nav>
    </MainCard>
  );
}
