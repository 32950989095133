import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
//import storageSession from 'redux-persist/lib/storage/session';

import { authenticationReducer } from './Reducers/authenticationReducer';
import { customization } from './Reducers/LayoutReducer';
import { notificationReducer } from './Reducers/handleNotificationReducer';
import { modalReducer } from './Reducers/modalReducer';
import { compositeDataReducer } from './Reducers/compositeDataReducer';
import { clientReducer } from './Reducers/clientReducer';

import { settingsReducer } from './Reducers/settingsReducer';

import { paymentsReducer } from './Reducers/paymentsReducer';
import { teamMembersReducer } from './Reducers/teamMembersReducer';

import { advisorProfileSettingsReducer } from './Reducers/advisorProfileSettingsReducer';

import { dashboardStatisticsReducer } from './Reducers/dashboardStatisticsReducer';

import {eventReducer} from './Reducers/eventReducer'

import { cashflowReducer } from './Reducers/cashflowReducer';

import { tasksReducer } from './Reducers/tasksReducer';

const authenticationPersistConfig = {
  key: 'authenticationReducer',
  storage: storage,
};
//app reducer
const appReducer = combineReducers({
  authenticationReducer: persistReducer(authenticationPersistConfig, authenticationReducer),
  customization: customization,
  notificationReducer: notificationReducer,
  modalReducer: modalReducer,
  compositeDataReducer: compositeDataReducer,
  clientReducer: clientReducer,
  settingsReducer: settingsReducer,

  paymentsReducer: paymentsReducer,
  teamMembersReducer: teamMembersReducer,

  advisorProfileSettingsReducer: advisorProfileSettingsReducer,

  //Dashboard
  dashboardStatisticsReducer: dashboardStatisticsReducer,

  //event
  eventReducer:eventReducer,

  //cash flow
  cashflowReducer: cashflowReducer,

  tasksReducer: tasksReducer,
});
//root reducer
const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'DESTROY_SESSION') state = undefined;

  return appReducer(state, action);
};

const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
export default Store;
