import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //Create New Client
  saveClientRequestLoading: false,
  saveClientData: {},
  saveClientFailed: {},

  //Request Client List
  clientListRequestLoading: false,
  clientListData: {},
  clientListFailed: {},

  //selected client detail
  isClientDetailRequestLoading: false,
  selectedClientDetail: {},
  selectedClientDetailFailed: {},

  //updateClient detail
  updateClientRequestLoading: false,
  updateClientData: {},
  updateClientFailed: {},

  //filter Clients detail
  filterClientsRequestLoading: false,
  filterClientsRequestSuccessData: null,
  filterClientsRequestErrorData: null,

  //Create Multiple Clients
  saveMultipleClientsRequestLoading: false,
  saveMultipleClientsData: {},
  saveMultipleClientsFailed: {},

};

export const clientReducer = createReducer(initialState, {
  //save client
  postSaveClientRequest: (state) => {
    state.saveClientRequestLoading = true;
    state.saveClientData = {};
    state.saveClientFailed = {};
  },
  postSaveClientSuccess: (state, action) => {
    state.saveClientRequestLoading = false;
    state.saveClientData = action.payload;
    state.saveClientFailed = {};
  },
  postSaveClientFailed: (state, action) => {
    state.saveClientRequestLoading = false;
    state.saveClientData = {};
    state.saveClientFailed = action.payload;
  },

  //client List
  getAllClientsRequest: (state) => {
    state.clientListRequestLoading = true;
    state.clientListData = {};
    state.clientListFailed = {};
  },
  getAllClientsSuccess: (state, action) => {
    state.clientListRequestLoading = false;
    state.clientListData = action.payload;
    state.clientListFailed = {};
  },
  getAllClientsFailed: (state, action) => {
    state.clientListRequestLoading = false;
    state.clientListData = {};
    state.clientListFailed = action.payload;
  },

  //find client detail
  getClientByIdRequest: (state) => {
    state.isClientDetailRequestLoading = true;
    state.selectedClientDetail = {};
    state.selectedClientDetailFailed = {};
  },
  getClientByIdRequestSuccess: (state, action) => {
    state.isClientDetailRequestLoading = false;
    state.selectedClientDetail = action.payload;
    state.selectedClientDetailFailed = {};
  },
  getClientByIdRequestFailed: (state, action) => {
    state.isClientDetailRequestLoading = false;
    state.selectedClientDetail = {};
    state.selectedClientDetailFailed = action.payload;
  },

  //update client
  patchUpdateClientRequest: (state) => {
    state.updateClientRequestLoading = true;
    state.updateClientData = {};
    state.updateClientFailed = {};
  },
  patchUpdateClientRequestSuccess: (state, action) => {
    state.updateClientRequestLoading = false;
    state.updateClientData = action.payload;
    state.updateClientFailed = {};
  },
  patchUpdateClientRequestFailed: (state, action) => {
    state.updateClientRequestLoading = false;
    state.updateClientData = {};
    state.updateClientFailed = action.payload;
  },

  //filter clients
  postFilterClientsRequest: (state) => {
    state.filterClientsRequestLoading = true;
    state.filterClientsRequestSuccessData = null;
    state.filterClientsRequestErrorData = null;
  },
  postFilterClientsRequestSuccess: (state, action) => {
    state.filterClientsRequestLoading = false;
    state.filterClientsRequestSuccessData = action.payload;
    state.filterClientsRequestErrorData = null;
  },
  postFilterClientsRequestFailed: (state, action) => {
    state.filterClientsRequestLoading = false;
    state.filterClientsRequestSuccessData = null;
    state.filterClientsRequestErrorData = action.payload;
  },

  //save Multiple Clients
  postSaveMultipleClientsRequest: (state) => {
    state.saveMultipleClientsRequestLoading = true;
    state.saveMultipleClientsData = {};
    state.saveMultipleClientsFailed = {};
  },
  postSaveMultipleClientsSuccess: (state, action) => {
    state.saveMultipleClientsRequestLoading = false;
    state.saveMultipleClientsData = action.payload;
    state.saveMultipleClientsFailed = {};
  },
  postSaveMultipleClientsFailed: (state, action) => {
    state.saveMultipleClientsRequestLoading = false;
    state.saveMultipleClientsData = {};
    state.saveMultipleClientsFailed = action.payload;
  },

});
