import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

import Dashboard from 'views/dashboard/Dashboard';

// ==============================|| client payments ||============================== //

const Guests = Loadable(lazy(() => import('views/clients/clientList/Guests')));
const ClientDashboard = Loadable(lazy(() => import('views/clients/dashboard/ClientDashboard')));
const MyProfile = Loadable(lazy(() => import('views/profile/myProfile/MyProfile')));
const CreateGuest = Loadable(lazy(() => import('views/clients/create/CreateGuest')));

const TeamMembersList = Loadable(
  lazy(() => import('views/teamMembers/teamMembersList/TeamMembersList'))
);

const CreateTeamMembers = Loadable(
  lazy(() => import('views/teamMembers/createTeamMembers/CreateTeamMembers'))
);

const CashflowDashboard = Loadable(
  lazy(() => import('views/cashflow/dashboard/CashflowDashboard'))
);
const CreateCashflow = Loadable(lazy(() => import('views/cashflow/create/CreateCashflow')));

const EventDashboard = Loadable(lazy(() => import('views/events/EventDashboard')));
const EventList = Loadable(lazy(() => import('views/events/EventList')));

const Task = Loadable(lazy(() => import('views/task/Task')));

const CreateTask = Loadable(lazy(() => import('views/task/createTask/CreateTask')));

const MainRoutes = {
  path: '/main',
  element: <MainLayout />,
  children: [
    {
      path: 'dashboard',
      element: <Dashboard />,
    },

    {
      path: 'my-profile',
      element: <MyProfile />,
    },

    {
      path: 'guests',
      element: <Guests />,
    },

    {
      path: 'guest-dashboard',
      element: <ClientDashboard />,
    },

    {
      path: 'guests/create',
      element: <CreateGuest />,
    },

    {
      path: 'team-members',
      element: <TeamMembersList />,
    },
    {
      path: 'team-members/create',
      element: <CreateTeamMembers />,
    },
    {
      path: 'cashflow',
      element: <CashflowDashboard />,
    },
    {
      path: 'events',
      element: <EventList />,
    },
    {
      path: 'events/eventschedule',
      element: <EventDashboard />,
    },
    {
      path: 'cashflow/create',
      element: <CreateCashflow />,
    },

    {
      path: 'task',
      element: <Task />,
    },
    {
      path: 'task/create',
      element: <CreateTask />,
    },
  ],
};

export default MainRoutes;
