// src/components/DashboardSkeleton.js
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

const DashboardSkeleton = ({ variant = 'rectangular', height = 100, width = '100%', sx = {} }) => {
  return (
    <Skeleton
      variant={variant}
      height={height}
      width={width}
      sx={{ borderRadius: '8px', backgroundColor: 'white', ...sx }}
    />
  );
};

export default DashboardSkeleton;
