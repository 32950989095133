import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Request All Events
 * @param {Function} handleFullEventSuccessCallback
 * @param {Function} handleFullEventFailedCallback
 * @returns
 */

export const requestAllEvents =
  (handleFullEventSuccessCallback = null, handleFullEventFailedCallback = null) =>
  async (dispatch) => {
    dispatch({ type: 'getFullEventRequest' });

    const requestData = {
      url: API_URL.event.GETAll,
      method: 'GET',
    };
    const successCallback = (response) => {
      handleFullEventSuccessCallback?.(response);
      dispatch({ type: 'getFullEventRequestSuccess', payload: response.data });
    };
    const errorCallback = (error) => {
      handleFullEventFailedCallback?.(error);
      dispatch({ type: 'getFullEventRequestFailed', payload: error });
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * get event
 * @param {object} requestBody
 * @param {Function} handleSaveSuccessCallback
 * @param {Function} handleSaveFailedCallback
 * @returns
 */
export const getEvent =
  ({ eventId }, successCallback = null, errorCallback = null) =>
  async (dispatch) => {
    dispatch({ type: 'getEventDataRequestSuccess' });
    // Construct the requestData object with the eventId
    const requestData = {
      url: API_URL.event.GET_BY_ID.replace('{eventId}', eventId),
      method: 'GET',
    };

    const onSuccess = (response) => {
      dispatch({ type: 'getEventDataRequestSuccess', payload: response.data });
      successCallback?.(response); // Invoke the success callback if provided
    };

    const onError = (error) => {
      const errorMessage = error?.response?.data?.name || 'Something went wrong. Try again!';
      dispatch({ type: 'getEventDataRequestFaild', payload: error });
      Store.dispatch(handleNotification(errorMessage, 'error'));
      errorCallback?.(error); // Invoke the error callback if provided
    };

    // Call the http_request with the requestData object
    http_request(requestData, onSuccess, onError);
  };

/**
 * create Event
 * @param {object} requestBody
 * @param {Function} handleSaveSuccessCallback
 * @param {Function} handleSaveFailedCallback
 * @returns
 */

export const createEvent =
  (requestAllData, createEventSuccessRequestCallback, createEventRequestFailCallback) =>
  async (dispatch) => {
    dispatch({ type: 'createEventRequest' });

    // const { requestBody } = requestAllData;
    console.log('/////// requestBody  ///////', requestAllData);
    console.log('/////// API_URL.event.CREATE  ///////', API_URL.event.CREATE);
    const requestData = {
      url: API_URL.event.CREATE,
      method: 'POST',
      data: requestAllData,
    };

    const successCallback = (response) => {
      dispatch({ type: 'createEventRequestSuccess', payload: response.data });
      const message = `The Event is created successfully.`;
      Store.dispatch(handleNotification(message, 'success'));
      createEventSuccessRequestCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'createEventRequestFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      createEventRequestFailCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * update cashflow
 * @param {object} requestBody
 * @param {Function} handleSaveSuccessCallback
 * @param {Function} handleSaveFailedCallback
 * @returns
 */
export const updateEvent =
  (
    requestAllData,
    updateEventRequestSuccessCallback,
    updateEventRequestFailCallback
  ) =>
  async (dispatch) => {
    dispatch({ type: "updateEventRequest" });

    const { requestBody,  eventId } = requestAllData;

    const requestData = {
      url: API_URL.event.UPDATE.replace('{eventId}', eventId),
      method: 'PATCH',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'updateEventRequestSuccess', payload: response.data });
      const message = `The Event is updated successfully.`;
      Store.dispatch(handleNotification(message, "success"));
      updateEventRequestSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'updateEventRequestFailed', payload: error });
      const message =
        error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, "error"));
      updateEventRequestFailCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * delete cashflow
 * @param {Function} handleSaveSuccessCallback
 * @param {Function} handleSaveFailedCallback
 * @returns
 */
export const deleteEvent =
  (
    requestAllData,
    deleteEventSuccessCallback,
    deleteEventFailCallback
  ) =>
  async (dispatch) => {
    dispatch({ type: "deleteEventRequest" });

    const { eventId } = requestAllData;

    const requestData = {
      url: API_URL.event.DELETE.replace('{eventId}', eventId).replace('{eventId}', eventId),
      method: 'DELETE',
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteEventRequestSuccess', payload: response.data });
      const message = `The Cashflow is deleted successfully.`;
      Store.dispatch(handleNotification(message, "success"));
      deleteEventSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteEventRequestFailed', payload: error });
      const message =
        error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, "error"));
      deleteEventFailCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
