import { Grid, Typography } from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import MainCard from 'ui-component/cards/MainCard';
import NoData from 'ui-component/NoData';

const COLORS = ['#3498db', '#00C49F', '#FFBB28', '#FF8042']; // Define colors for different chart bars

const Chart = ({ chartData = [] }) => {
  let total = 0;
  let totalAccepted = 0;
  let totalPending = 0;
  let totalDeclined = 0;

  // Calculate totals for each type based on chartData
  chartData?.array?.forEach((item) => {
    total = chartData.totalUsers;
    if (item.name === 'Accepted') {
      totalAccepted += item.value;
    } else if (item.name === 'Pending') {
      totalPending += item.value;
    } else if (item.name === 'Declined') {
      totalDeclined += item.value;
    }
  });

  const dataToRender = [
    { name: 'Total', value: total, fill: '#3498db' },
    { name: 'Accepted', value: totalAccepted, fill: '#00C49F' },
    { name: 'Pending', value: totalPending, fill: '#FFBB28' },
    { name: 'Declined', value: totalDeclined, fill: '#FF8042' },
  ];

  // Custom legend formatter
  const renderLegendText = (value) => {
    switch (value) {
      case 'Total':
        return 'Total Invitations';
      case 'Accepted':
        return 'Accepted Invitations';
      case 'Pending':
        return 'Pending Invitations';
      case 'Declined':
        return 'Declined Invitations';
      default:
        return value;
    }
  };

  return (
    <MainCard
      sx={{
        cursor: 'pointer',
        borderRadius: '16px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(135deg, #f5f5f5, #ffffff)',
        transition: 'all 0.3s ease',
        padding: '20px',
        border: '1px solid #e0e0e0',
        overflow: 'hidden',
        ':hover': {
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
          transform: 'scale(1.02)',
        },
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            sx={(theme) => ({
              color: theme.palette.blue?.[600],
              fontWeight: theme.typography.fontWeightBold,
              fontSize: '1.2rem',
              mb: 2,
            })}
          >
            Invitation Overview
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {total > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={dataToRender} margin={{ top: 5, right: 8, left: 5, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill={(entry) => entry.fill} nameKey="name">
                  {dataToRender.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <ResponsiveContainer width="100%" height={300}>
              <NoData height={'12rem'} />
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                sx={{ padding: '20px', paddingTop: '40px' }}
              >
                No Data, Add New Guests here!
              </Typography>
            </ResponsiveContainer>
          )}
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default Chart;
