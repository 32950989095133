import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //Client Dashboard
  clientStatisticsLoading: false,
  clientStatisticsData: {},
  clientStatisticsError: null,
};

export const dashboardStatisticsReducer = createReducer(initialState, {
  // Client Dashboard Statics
  getDashboardStatisticsRequest: (state) => {
    state.clientStatisticsLoading = true;
    state.clientStatisticsData = {};
    state.clientStatisticsError = null;
  },
  getDashboardStatisticsRequestSuccess: (state, action) => {
    state.clientStatisticsLoading = false;
    state.clientStatisticsData = action.payload;
    state.clientStatisticsError = null;
  },
  getDashboardStatisticsRequestFailed: (state, action) => {
    state.clientStatisticsLoading = false;
    state.clientStatisticsData = {};
    state.clientStatisticsError = action.payload;
  },
});
