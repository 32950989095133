/**
 * Get Dynamic Base from ENV
 * @returns {object} {Base URL, Current Environment}
 */
const handleGetBaseAndEnv = () => {
  let baseURL,
    currentEnv,
    currentPortal = null;

  //* DEFAULT ADVISOR_PORTAL BASE PATHS
  let DEV_BASE = 'https://5989ao1c54.execute-api.ap-southeast-1.amazonaws.com/';
  let QA_BASE = 'https://olv31xba27.execute-api.ap-southeast-1.amazonaws.com/';
  let UAT_BASE = 'https://c3h7ys0bea.execute-api.ap-southeast-1.amazonaws.com/';
  let LOCAL_BASE = 'http://localhost:4002/';
  let PROD_BASE = 'https://4sx6aiqch6.execute-api.ap-southeast-1.amazonaws.com/';

  let nodeProcessCurrentEnv = process.env?.['REACT_APP_ENV'];
  let nodeProcessCurrentPortal = process.env?.['REACT_APP_PORTAL'];

  currentEnv = nodeProcessCurrentEnv || 'dev';
  currentPortal = nodeProcessCurrentPortal || '';

  if (currentEnv === 'dev') {
    baseURL = DEV_BASE;
  } else if (currentEnv === 'qa') {
    baseURL = QA_BASE;
  } else if (currentEnv === 'uat') {
    baseURL = UAT_BASE;
  } else if (currentEnv === 'prod') {
    baseURL = PROD_BASE;
  } else if (currentEnv === 'local') {
    baseURL = LOCAL_BASE; //4002 httpPort
  }

  return { baseURL, currentEnv, currentPortal };
};

const { baseURL, currentEnv, currentPortal } = handleGetBaseAndEnv();

const basePath = baseURL + currentEnv;

const API_URL = {
  baseURL,
  basePath,
  currentEnv,
  currentPortal,

  auth: {
    POST_SIGN_UP_ACCOUNT_OWNER: basePath + '/signup/account-owner',
    POST_SIGN_IN_STAFF: basePath + '/login/staff',
    GET_LOGOUT: basePath + '/logout',
    PUT_UPDATE_PASSWORD: basePath + '/password-reset/update-password',
    POST_RESET_USER_PASSWORD: basePath + '/client/fileNote/client/{clientId}/fileNote/{fileNoteId}',
  },
  teamMember: {
    POST_CREATE_TEAM_MEMBER: basePath + '/team-member',
    PATCH_UPDATE_TEAM_MEMBER: basePath + '/team-member',
    POST_FILTER_TEAM_MEMBER: basePath + '/team-member/filter',
  },
  client: {
    clientList: {
      GET_ALL_CLIENTS: basePath + '/client',
      POST_CREATE_NEW_CLIENT: basePath + '/client',
      POST_CREATE_NEW_MULTIPLE_CLIENTS: basePath + '/multipleClients',
      POST_FIND_FILTERED_CLIENTS: basePath + '/client/filter',
      PATCH_UPDATE_CLIENT: basePath + '/client/filter',
      GET_FIND_CLIENT_BY_CLIENT_ID: basePath + '/client/{clientId}',
      PATCH_UPDATE_CLIENT_PROFILE_BY_ID: basePath + '/client/{clientId}',
      DELETE_CLIENT_BY_ID: basePath + '/client/{clientId}',
    },
  },
  teamMembers: {
    POST_SAVE_TEAM_MEMBER: basePath + '/team-member',
    GET_ALL_TEAM_MEMBERS: basePath + '/team-members',
    GET_TEAM_MEMBER_FOR_ACCOUNT_OWNER_BY_ID: basePath + '/team-members/{accountOwnerId}',
    PATCH_UPDATE_TEAM_MEMBER: basePath + '/team-member/{teamMemberId}',
    DELETE_TEAM_MEMBER_BY_ID: basePath + '/team-members/{teamMemberId}',
    POST_UPLOAD_TEAM_MEMBER_PROFILE_PIC: basePath + '/team-members/{teamMemberId}',
    POST_SEARCH_TEAM_MEMBERS_BY_FILTERS: basePath + '/team-member/filter',
  },
  composites: {
    GET_ALL_ENUMS: basePath + '/enums',
  },
  dashboard: {
    DASHBOARD_STATISTICS: basePath + '/client-statistics',
  },
  cashflow: {
    CREATE: basePath + '/createCashflow/financialPosition/{financialPositionId}',
    UPDATE:
      basePath + '/updateCashflow/financialPosition/{financialPositionId}/cashflow/{cashflowId}',
    DELETE:
      basePath + '/deleteCashflow/financialPosition/{financialPositionId}/cashflow/{cashflowId}',
    GET: basePath + '/getCashflow/financialPosition/{financialPositionId}',
  },
  tasks: {
    POST_SAVE_TASKS: basePath + '/createTask',
    PUT_UPDATE_TASK: basePath + '/updateTask/task/{taskId}',
    DELETE_TASK_BY_TASK_ID: basePath + '/deleteTask/task/{taskId}',
    GET_ALL_TASKS: basePath + '/getAllTask',
    GET_FIND_TASK_BY_ID: basePath + '/getTask/task/{taskId}',
    POST_SEARCH_TASKS_BY_FILTERS: basePath + '/tasks/filter',
    GET_ALL_TASK_ASSIGNEES: basePath + '/tasks/assignees',
  },
  event: {
    GET_BY_ID: basePath + '/getEvent/event/{eventId}',
    GETAll: basePath + '/getAllEvent',
    CREATE: basePath + '/createEvent',
    UPDATE: basePath + '/updateEvent/event/{eventId}',
    DELETE: basePath + '/deleteEvent/event/{eventId}',
  },
};

export { API_URL };
