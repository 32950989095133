import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import {
  closeReAuthenticateModal,
  requestSignIn,
  handleSignOut,
} from 'redux/Actions/authenticationAction';

import { Login as LoginIcon, Visibility, VisibilityOff } from '@mui/icons-material';
const ReAuthenticationForm = (props) => {
  const { formTitle } = props;
  /**
   * Redux Integrations
   */
  const dispatch = useDispatch();
  //login user data
  const loginUserData = useSelector((state) => state.authenticationReducer?.loginData);

  //states
  const [showPassword, setShowPassword] = useState(false);
  /**
   * Re-Authenticate initial values
   */
  const reAuthInitialValues = {
    password: '',
  };
  /**
   * Re-Authenticate Schema
   */
  const reAuthValidationSchema = Yup.object({
    password: Yup.string().required('Required !').typeError('Invalid Password !'),
  });

  /**
   * Handle Submit form
   * @param {*} values
   * @param {*} submitProps
   */
  const onSubmit = (values, submitProps) => {
    submitProps?.setSubmitting && submitProps.setSubmitting(true);
    const rewAuthenticationData = {
      domain: 'Account Owner',
      userRole: 'Account Owner',
      accountRole: 'Account Owner',
      accountCode: loginUserData?.accountCode,
      emailAddress: loginUserData?.user?.emailAddress,
      password: values?.password,
      rememberMe: true,
    };
    const handleReAuthenticateSuccessCallback = (response) => {
      submitProps?.setSubmitting && submitProps.setSubmitting(false);
      dispatch(closeReAuthenticateModal());
    };
    const handleReAuthenticateFailedCallback = (error) => {
      submitProps?.setSubmitting && submitProps.setSubmitting(false);
    };

    dispatch(
      requestSignIn(
        rewAuthenticationData,
        handleReAuthenticateSuccessCallback,
        handleReAuthenticateFailedCallback
      )
    );
  };
  /**
   * Handle Click on Sign Out
   * @param {*} event
   */
  const handleLogOut = (event) => {
    dispatch(handleSignOut());
  };
  /**
   * Handle show password
   */
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  /**
   * click on password <fieldset></fieldset>
   * @param {*} event
   */
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Formik
      validationSchema={reAuthValidationSchema}
      initialValues={reAuthInitialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs="auto">
              <LoginIcon fontSize="small" color="green" />
            </Grid>
            <Grid item xs={true}>
              <Typography variant="h5" color="grey.700">
                {formTitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs="auto">
                  <Button
                    id="reAuthenticate-signOut"
                    size="large"
                    color="orange"
                    variant="outlined"
                    onClick={handleLogOut}
                  >
                    Sign Out
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <LoadingButton
                    id={'reAuthenticate-signIn'}
                    disableElevation
                    disabled={formik?.isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                    color={'secondary'}
                    loadingPosition="end"
                    loading={formik?.isSubmitting}
                    endIcon={<LoginIcon />}
                  >
                    Sign in
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

ReAuthenticationForm.defaultProps = {
  formTitle: 'Your session is over. Please enter your password and login again fro a New Session !',
};

export default ReAuthenticationForm;
