import axios from 'axios';
import Store from 'redux/Store';
import { isEmpty, isNil } from 'ramda';

import { handleNotification } from 'redux/Actions/handleNotificationAction';
import { openReAuthenticateModal } from 'redux/Actions/authenticationAction';

const { API_URL } = require('shared/API_URLS');

/**
 * Configured Axios Instance
 */
const axiosInstance = axios.create({
  baseURL: API_URL.basePath,
  timeout: 90000,
  withCredentials: false,
});

/**
 * Validate url equality
 * @param {string} url means the called API URL with the Query parameters
 * @param {string} iterationUrl  means direct value from API_URL
 * @returns
 */
const validateUrlMatch = (url, iterationUrl) => {
  let iterationUrlParts = iterationUrl.split('/');
  let urlParts = url.split('/');

  let isUrlMatches = false;

  if (
    Array.isArray(iterationUrlParts) &&
    Array.isArray(urlParts) &&
    iterationUrlParts?.length === urlParts?.length
  ) {
    isUrlMatches = iterationUrlParts.reduce((isPrevMatched, currentPart, currentPartIndex) => {
      let isCurrentPartMatched = false;
      if (isPrevMatched && urlParts?.[currentPartIndex] === currentPart) {
        isCurrentPartMatched = true;
      } else if (
        isPrevMatched &&
        urlParts?.[currentPartIndex] !== currentPart &&
        (currentPart.includes('{') || currentPart.includes('}'))
      ) {
        isCurrentPartMatched = true;
      } else {
        isCurrentPartMatched = false;
      }
      return isCurrentPartMatched;
    }, true);
  }
  return isUrlMatches;
};
/**
 * @typedef {Object} BaseAndAPIKeyData
 * @property {string} moduleAPIKey - module's X-API-KEY
 * @property {string} moduleBasePath - module's Base Path
 * @property {boolean} currentIterationUrlMatchFound - recursive iteration found the matched URL
 */
/**
 * Get Module Base Path and API Key
 * @param {string} url
 * @param {object} entryObjectValue
 * @param {string} currentModuleVariable
 * @param {string} currentModuleBasePath
 * @param {boolean} isUrlValueFound
 * @returns { BaseAndAPIKeyData}
 */
const handleGetModuleAPIKey = (
  url,
  entryObjectValue = null,
  currentModuleVariable = null,
  currentModuleBasePath = null,
  isUrlValueFound = false
) => {
  const API_URL_OBJECT = entryObjectValue || { ...API_URL };
  let assessedModuleVariable = currentModuleVariable;
  let assessedModuleBasePath = currentModuleBasePath;
  let currentIterationUrlMatchFound = isUrlValueFound;
  let [moduleAPIKey, moduleBasePath] = [null, null];
  Object.entries(API_URL_OBJECT).every((entryKeyValue, firstIndex) => {
    let [entryKey, entryValue] = entryKeyValue;
    if (entryKey === 'moduleVariable') {
      assessedModuleVariable = entryValue;
    }
    if (entryKey === 'moduleBasePath') {
      assessedModuleBasePath = entryValue;
    }
    if (typeof entryValue === 'string' && validateUrlMatch(url, entryValue)) {
      currentIterationUrlMatchFound = true;
      return false;
    } else if (typeof entryValue === 'object' && !isEmpty(entryValue) && !isNil(entryValue)) {
      let basePathAndAPIKeyData = handleGetModuleAPIKey(
        url,
        entryValue,
        assessedModuleVariable,
        assessedModuleBasePath,
        currentIterationUrlMatchFound
      );
      if (
        typeof basePathAndAPIKeyData === 'object' &&
        !isEmpty(basePathAndAPIKeyData) &&
        !isNil(basePathAndAPIKeyData) &&
        basePathAndAPIKeyData?.isUrlValueFound
      ) {
        moduleAPIKey = basePathAndAPIKeyData?.moduleAPIKey;
        moduleBasePath = basePathAndAPIKeyData?.moduleBasePath;
        currentIterationUrlMatchFound = basePathAndAPIKeyData?.isUrlValueFound;
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  });

  if (assessedModuleVariable && assessedModuleBasePath) {
    moduleAPIKey =
      process.env[
        `REACT_APP_${API_URL.currentEnv.toLocaleUpperCase()}_${API_URL.currentPortal.toLocaleUpperCase()}_${assessedModuleVariable}_API_KEY`
      ];
    moduleBasePath = assessedModuleBasePath;
  }
  return {
    moduleAPIKey,
    moduleBasePath,
    isUrlValueFound: currentIterationUrlMatchFound,
  };
};
/**
 * @typedef {Object} AXIOSInstanceData
 * @property {import('axios').AxiosInstance} axiosRequest - axios request configured instance
 * @property {AbortController} controller - AbortController on axios Signal (A controller object that allows you to abort one or more DOM requests as and when desired.)
 * @property {function} abortRequest - Function callback to Abort invoked request
 */
/**
 * Shared HTTP Request Function
 * @param {object} requestData
 * @param {Function} successCallback
 * @param {Function} errorCallback
 * @param {object | undefined} otherConfigs
 * @returns {AXIOSInstanceData}
 */
export default function http_request(
  requestData,
  successCallback,
  errorCallback,
  otherConfigs = {}
) {
  const { method, url, ...request } = requestData;

  const controller = new AbortController();
  let authReducerData = Store.getState().authenticationReducer?.loginData;

  const isToken = authReducerData?.token;
  if (
    (typeof otherConfigs?.headers === 'object' || isToken) &&
    typeof axiosInstance?.defaults?.headers === 'object'
  ) {
    //*normal request with token
    let commonAuthHeaders = {};
    if (isToken && !otherConfigs?.noToken) {
      commonAuthHeaders['Authorization'] = `Bearer ${isToken}`;
    }
    // some apis does not require token
    if (!otherConfigs?.noToken) {
      axiosInstance.defaults.headers.common = {
        [isToken && 'Authorization']: `Bearer ${isToken}`,
        ...commonAuthHeaders,
      };
    }
    if (otherConfigs?.noToken) {
      axiosInstance.defaults.headers.common['Authorization'] = undefined;
    }
  }

  const { moduleAPIKey, moduleBasePath, isUrlValueFound } = handleGetModuleAPIKey(url);
  if (moduleAPIKey && isUrlValueFound) {
    //*Sub modules are broken to different backends
    axiosInstance.defaults.baseURL = moduleBasePath;
    axiosInstance.defaults.headers.common['x-api-key'] = moduleAPIKey;
  } else {
    //*General Backend
    axiosInstance.defaults.baseURL = API_URL.basePath;
    axiosInstance.defaults.headers.common['x-api-key'] =
      process.env[
        `REACT_APP_${API_URL.currentEnv.toLocaleUpperCase()}_${API_URL.currentPortal.toLocaleUpperCase()}_ADVISOR_PORTAL_API_KEY`
      ];
  }
  axiosInstance.defaults.headers.common['Accept'] = 'application/json';
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';
  if (
    typeof otherConfigs?.headers === 'object' &&
    !isEmpty(otherConfigs?.headers) &&
    !isNil(otherConfigs?.headers)
  ) {
    //*Content-Type or any other request header to attach on custom request
    axiosInstance.defaults.headers = {
      ...axiosInstance.defaults.headers,
      ...otherConfigs.headers,
    };
  }

  //request success response handler
  const handleSuccessResponse = (response) => {
    if (
      (response?.status === 200 || response.status === 201) &&
      (response?.data || otherConfigs?.noToken)
    ) {
      typeof successCallback === 'function' && successCallback(response);
    } else {
      Store.dispatch(handleNotification('Please Check your Network Connection', 'error'));
    }
  };
  //request error response handler
  const handleErrorResponse = (error) => {
    if (error?.code === 'ECONNABORTED') {
      let message = 'Please Check your Network Connection and Try Again..';
      Store.dispatch(handleNotification(message, 'error'));
    }
    if (error?.response?.status === 401) {
      Store.dispatch(
        handleNotification('Your Current Session is Over. Please Login and Try Again !', 'error')
      );

      setTimeout(() => {
        Store.dispatch(openReAuthenticateModal());
      }, [300]);
    }
    // else if (!error?.response && !isToken && !JSON.parse(JSON.parse(authReducerData)?.loginData)?.user) {
    //   //auto sign-out when login user and token is not defined on store
    //   Store.dispatch(
    //     handleNotification('Please Login and Try Again !', 'error')
    //   );
    //   setTimeout(() => {
    //     Store.dispatch(handleSignOut(redirect));
    //   }, [300]);
    // }
    typeof errorCallback === 'function' && errorCallback(error);
  };

  let axiosRequest = axiosInstance({
    method,
    url,
    signal: controller.signal,
    withCredentials: false,
    ...request,
  });
  if (successCallback && errorCallback) {
    axiosRequest.then(handleSuccessResponse).catch(handleErrorResponse);
  }
  /**
   * Handle abort callback - to use on optimizations
   * @param {*} abortReason
   */
  function abortRequest(abortReason = null) {
    controller?.signal &&
      !controller.signal.aborted &&
      ((abortReason && controller.abort(abortReason)) || controller.abort());
  }
  return { axiosRequest, controller, abortRequest };
}
