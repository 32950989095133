import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //Create New team member
  saveTeamMemberRequestLoading: false,
  saveTeamMemberData: {},
  saveTeamMemberFailed: {},

  isTeamMembersListLoading: false,
  teamMembersListData: null,
  teamMembersListFailed: null,

  deleteTeamMemberRequestLoading: false,
  deleteTeamMemberData: null,
  deleteTeamMemberFailed: null,

  updateTeamMemberRequestLoading: false,
  updateTeamMemberData: null,
  updateTeamMemberFailed: null,
};

export const teamMembersReducer = createReducer(initialState, {
  //save team member
  postSaveTeamMemberRequest: (state) => {
    state.saveTeamMemberRequestLoading = true;
    state.saveTeamMemberData = {};
    state.saveTeamMemberFailed = {};
  },
  postSaveTeamMemberSuccess: (state, action) => {
    state.saveTeamMemberRequestLoading = false;
    state.saveTeamMemberData = action.payload;
    state.saveTeamMemberFailed = {};
  },
  postSaveTeamMemberFailed: (state, action) => {
    state.saveTeamMemberRequestLoading = false;
    state.saveTeamMemberData = {};
    state.saveTeamMemberFailed = action.payload;
  },

  //get all team members
  postGetTeamMembersRequest: (state) => {
    state.isTeamMembersListLoading = true;
    state.teamMembersListData = [];
    state.teamMembersListFailed = {};
  },
  postGetTeamMembersSuccess: (state, action) => {
    state.isTeamMembersListLoading = false;
    state.teamMembersListData = action.payload;
    state.teamMembersListFailed = {};
  },
  postGetTeamMembersFailed: (state, action) => {
    state.isTeamMembersListLoading = false;
    state.teamMembersListData = [];
    state.teamMembersListFailed = action.payload;
  },

  //delete team member
  deleteTeamMemberRequest: (state) => {
    state.deleteTeamMemberRequestLoading = true;
    state.deleteTeamMemberData = [];
    state.deleteTeamMemberFailed = {};
  },
  deleteTeamMemberSuccess: (state, action) => {
    state.deleteTeamMemberRequestLoading = false;
    state.deleteTeamMemberData = action.payload;
    state.deleteTeamMemberFailed = {};
  },
  deleteTeamMemberFailed: (state, action) => {
    state.deleteTeamMemberRequestLoading = false;
    state.deleteTeamMemberData = [];
    state.deleteTeamMemberFailed = action.payload;
  },

  //update team member
  updateTeamMemberRequest: (state) => {
    state.updateTeamMemberRequestLoading = true;
    state.updateTeamMemberData = [];
    state.updateTeamMemberFailed = {};
  },
  updateTeamMemberSuccess: (state, action) => {
    state.updateTeamMemberRequestLoading = false;
    state.updateTeamMemberData = action.payload;
    state.updateTeamMemberFailed = {};
  },
  updateTeamMemberFailed: (state, action) => {
    state.updateTeamMemberRequestLoading = false;
    state.updateTeamMemberData = [];
    state.updateTeamMemberFailed = action.payload;
  },

  //search Team Members
  postFilterTeamMembersRequest: (state) => {
    state.isPostFilterTeamMembersRequestLoading = true;
    state.postFilterTeamMembersRequestSuccessData = [];
    state.postFilterTeamMembersRequestFailedData = null;
  },
  postFilterTeamMembersRequestSuccess: (state, action) => {
    state.isPostFilterTeamMembersRequestLoading = false;
    state.postFilterTeamMembersRequestSuccessData = action.payload;
    state.postFilterTeamMembersRequestFailedData = null;
  },
  postFilterTeamMembersRequestFailed: (state, action) => {
    state.isPostFilterTeamMembersRequestLoading = false;
    state.postFilterTeamMembersRequestSuccessData = [];
    state.postFilterTeamMembersRequestFailedData = action.payload;
  },
});
