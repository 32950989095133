// MUI Imports
import { Grid, Typography, useMediaQuery } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';

// Custom Styles

const DashboardMiniCard = (props) => {

// Props
const { title, value, color } = props;
const isSmallScreen = useMediaQuery('(max-width: 994px)'); // For screens smaller than 994px

  return (
    <MainCard
      content={false}
      sx={{
        padding: 3,
        borderRadius: '16px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(135deg, #f5f5f5, #ffffff)',
        transition: 'transform 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
        },
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={(theme) => ({
              color: theme.palette.palette?.grey900,
              fontSize: isSmallScreen ? '1.25rem' : '1.75rem', // Adjust font size for small screens
              fontWeight: 700,
              textAlign: 'center',
              letterSpacing: '0.05em',
            })}
            noWrap
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{
              color: { color },
              fontSize: isSmallScreen ? '1.75rem' : '2.25rem', // Adjust font size for small screens
              fontWeight: 800,
              textAlign: 'center',
            }}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default DashboardMiniCard;
