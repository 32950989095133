import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestDashboardStatistics } from 'redux/Actions/dashboardActions';
import MainCard from 'ui-component/cards/MainCard';
import Cashflow from './cards/Cashflow';
import Chart from './cards/Chart';
import {
  default as DashboardMainCards,
  default as DashboardMiniCard,
} from './cards/DashboardMiniCard';
import DashboardMiniCardByThree from './cards/DashboardMiniCardByThree';
import TaskList from './cards/TaskList';
import TimeLine from './cards/TimeLine';

import DashboardSkeleton from './DashboardSkeleton'

const miniData = [
  {
    title: 'Vegetarian',
    value: '24',
    avatarIcon: 'total-invitations',
    textColor: '#161716', // primary
    change: 'positive',
    changeNumber: '29%',
    subTitle: 'Total User',
  },
  {
    title: 'Alcohol',
    value: '64',
    avatarIcon: 'accepted-invitations',
    textColor: '#161716',
    change: 'positive',
    changeNumber: '18%',
    subTitle: 'Last week analytics',
  },
];

const fieldsToMap = {
  //accepted: { label: 'Accepted', color: '#2ecc71' },
  totalUsers: { label: 'Invited', color: '#3498db' },
  pending: { label: 'Pending', color: '#f1c40f' },
  declined: { label: 'Declined', color: '#e74c3c' },
};

const UserListCards = () => {
  const dispatch = useDispatch();
  const dashboardStatisticsdata = useSelector((state) => state.dashboardStatisticsReducer);
  const { clientStatisticsData, clientStatisticsError, clientStatisticsLoading } =
    dashboardStatisticsdata;

  useEffect(() => {
    dispatch(requestDashboardStatistics());
  }, [dispatch]);

  console.log('-----------dashboardStatisticsdata--------------', dashboardStatisticsdata);

  return (
    <Grid container spacing={3} padding={2}>
      {/* Key Statistic Cards */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {/* Static Example Card */}
          <Grid item xs={12} sm={6} md={3}>
            {clientStatisticsLoading ? (
              <DashboardSkeleton variant="rectangular" height={100} /> //width={width}
            ) : (
              <DashboardMiniCardByThree
                title="Accepted"
                value="100"
                color="#2ecc71"
                sx={{
                  padding: '16px',
                  borderRadius: '12px',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                }}
                clientStatisticsData={clientStatisticsData}
              />
            )}
          </Grid>
          {Object.keys(fieldsToMap).map((key) => (
            <Grid item xs={12} sm={6} md={3} key={key}>
              {clientStatisticsLoading ? (
                <DashboardSkeleton />
              ) : (
                <DashboardMainCards
                  title={fieldsToMap[key].label}
                  value={clientStatisticsData[key]}
                  color={fieldsToMap[key].color}
                  sx={{
                    padding: '16px',
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  }}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* Chart and Cashflow Section */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {clientStatisticsLoading ? (

              <DashboardSkeleton variant="rectangular" height={300} /> 
            ) : (
              <Chart chartData={clientStatisticsData} />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            {clientStatisticsLoading ? (
              <DashboardSkeleton variant="rectangular" height={300} />
            ) : (
              <Cashflow />
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* Marketing Section */}
      <Grid item xs={12}>
        {clientStatisticsLoading ? (
          <DashboardSkeleton />
        ) : (
          <MainCard
            sx={{
              padding: '16px',
              borderRadius: '12px',
              boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
            }}
          >
            <h1>Marketing Overview.</h1>
            <p>Review the latest marketing insights and trends.</p>
          </MainCard>
        )}
      </Grid>
      {/* Timeline and Task List */}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="stretch" style={{ height: '100%' }}>
          {/* TimeLine Column */}
          <Grid item xs={12} md={6} style={{ display: 'flex' }}>
            {clientStatisticsLoading ? <DashboardSkeleton /> : <TimeLine style={{ flex: 1 }} />}
          </Grid>
          {/* Task List Column */}
          <Grid item xs={12} md={6} style={{ display: 'flex' }}>
            {clientStatisticsLoading ? <DashboardSkeleton /> : <TaskList style={{ flex: 1 }} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserListCards;
