import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * create Cashflow
 * @param {object} requestBody
 * @param {Function} handleSaveSuccessCallback
 * @param {Function} handleSaveFailedCallback
 * @returns
 */
export const createCashflow =
  (requestAllData, createCashflowRequestSuccessCallback, createCashflowRequestFailCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'createCashflowRequest' });

    const { requestBody, financialPositionId } = requestAllData;

    const requestData = {
      url: API_URL.cashflow.CREATE.replace('{financialPositionId}', financialPositionId),
      method: 'POST',
      data: requestBody,
    };

    const successCallback = (response) => {
      dispatch({ type: 'createCashflowSuccess', payload: response.data });
      const message = `The Cashflow is created successfully.`;
      Store.dispatch(handleNotification(message, 'success'));
      createCashflowRequestSuccessCallback && createCashflowRequestSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'createCashflowFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      createCashflowRequestFailCallback && createCashflowRequestFailCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * update cashflow
 * @param {object} requestBody
 * @param {Function} handleSaveSuccessCallback
 * @param {Function} handleSaveFailedCallback
 * @returns
 */
export const updateCashflow =
  (requestAllData, updateCashflowRequestSuccessCallback, updateCashflowRequestFailCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'updateCashflowRequest' });

    const { requestBody, financialPositionId, cashflowId } = requestAllData;

    const requestData = {
      url: API_URL.cashflow.UPDATE.replace('{financialPositionId}', financialPositionId).replace(
        '{cashflowId}',
        cashflowId
      ),
      method: 'PATCH',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'updateCashflowSuccess', payload: response.data });
      const message = `The Cashflow is updated successfully.`;
      Store.dispatch(handleNotification(message, 'success'));

      updateCashflowRequestSuccessCallback && updateCashflowRequestSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'updateCashflowFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      updateCashflowRequestFailCallback && updateCashflowRequestFailCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * delete cashflow
 * @param {Function} handleSaveSuccessCallback
 * @param {Function} handleSaveFailedCallback
 * @returns
 */
export const deleteCashflow =
  (requestAllData, deleteCashflowRequestSuccessCallback, deleteCashflowRequestFailCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteCashflowRequest' });

    const { financialPositionId, cashflowId } = requestAllData;

    const requestData = {
      url: API_URL.cashflow.DELETE.replace('{financialPositionId}', financialPositionId).replace(
        '{cashflowId}',
        cashflowId
      ),
      method: 'DELETE',
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteCashflowSuccess', payload: response.data });
      const message = `The Cashflow is deleted successfully.`;
      Store.dispatch(handleNotification(message, 'success'));

      deleteCashflowRequestSuccessCallback && deleteCashflowRequestSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteCashflowFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      deleteCashflowRequestFailCallback && deleteCashflowRequestFailCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * get cashflow
 * @param {object} requestBody
 * @param {Function} handleSaveSuccessCallback
 * @param {Function} handleSaveFailedCallback
 * @returns
 */
export const getCashflow =
  (requestAllData, getCashflowRequestSuccessCallback, getCashflowRequestFailCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getCashflowRequest' });

    const { financialPositionId } = requestAllData;

    const requestData = {
      url: API_URL.cashflow.GET.replace('{financialPositionId}', financialPositionId),
      method: 'get',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getCashflowSuccess', payload: response.data });

      getCashflowRequestSuccessCallback && getCashflowRequestSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getCashflowFailed', payload: error });
      const message = error?.response?.data?.name || `Something went wrong. Try again !`;
      Store.dispatch(handleNotification(message, 'error'));
      getCashflowRequestFailCallback && getCashflowRequestFailCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
