// MUI Imports
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';

const DashboardMiniCardByThree = ({ clientStatisticsData }) => {
  // Props
  //const { title, value, color } = props;
  const theme = useTheme();
  const cardData = clientStatisticsData;
  const isSmallScreen = useMediaQuery('(max-width: 994px)'); // For screens smaller than 994px
  console.log('++++++++ cardData +++++++', cardData.clientStatisticsData);
  console.log('++++++++ cardData.accepted +++++++', cardData.accepted);

  return (
    <MainCard
      content={false}
      sx={{
        borderRadius: '16px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(135deg, #fdf7f8, #fffaf0)', // Softer pastels, very light
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.25)',
        },
        margin: 0,
        padding: 0,
      }}
    >
      <Grid container>
        {/* First Row: Single Line for Title and Value */}
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: 'linear-gradient(135deg, #ffe6eb, #fff7d6)', // Very light red and yellow pastel
              padding: 2,
              borderRadius: '8px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.palette?.grey900,
                fontSize: isSmallScreen ? '1.2rem' : '1.7rem', // Adjust font size for small screens
                fontWeight: 700,
                textAlign: 'center',
                letterSpacing: '0.05em',
              }}
              noWrap
            >
              Accepted&nbsp;
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: '#00C49F',
                fontSize: isSmallScreen ? '1.5rem' : '2rem', // Adjust font size for small screens
                fontWeight: 900,
              }}
            >
              {cardData.accepted}
            </Typography>
          </Box>
        </Grid>

        {/* Second Row: Two Columns */}
        <Grid item xs={6}>
          <Box
            sx={{
              background: 'linear-gradient(135deg, #e7fbf9, #f2fff8)', // Soft mint and aqua for vegetarian
              padding: 2,
              borderRadius: '8px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.palette?.grey900,
                fontSize: isSmallScreen ? '0.7rem' : '0.9rem', // Adjust font size for small screens
                fontWeight: 600,
                textAlign: 'center',
                letterSpacing: '0.05em',
              }}
              noWrap
            >
              Vegetarian
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.primary.main,
                fontSize: isSmallScreen ? '0.8rem' : '1rem', // Adjust font size for small screens
                fontWeight: 700,
                textAlign: 'center',
              }}
              noWrap
            >
              {cardData.vegetarian}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              background: 'linear-gradient(135deg, #ffeef5, #fff3f8)', // Light pinks for alcohol section
              padding: 2,
              borderRadius: '8px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.palette?.grey900,
                fontSize: isSmallScreen ? '0.7rem' : '0.9rem', // Adjust font size for small screens
                fontWeight: 600,
                textAlign: 'center',
                letterSpacing: '0.05em',
              }}
              noWrap
            >
              Alcohol
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.primary.main,
                fontSize: isSmallScreen ? '0.8rem' : '1rem', // Adjust font size for small screens
                fontWeight: 700,
                textAlign: 'center',
              }}
              noWrap
            >
              {cardData.alcoholic}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default DashboardMiniCardByThree;
