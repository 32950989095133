import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Save Client Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestSaveClient =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveClientRequest' });

    const requestData = {
      url: API_URL.client.clientList.POST_CREATE_NEW_CLIENT,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveClientSuccess', payload: response.data });
      const successMessage = `Client is Saved Successfully !`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveClientFailed', payload: error });
      let message = error?.response?.data?.name || 'Client Save is Failed !';
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Request Client List
 * @param {Function} handleClientListSuccessCallback
 * @param {Function} handleClientListFailedCallback
 * @returns
 */
export const requestClientList =
  (handleClientListSuccessCallback, handleClientListFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getAllClientsRequest' });

    const requestData = {
      url: API_URL.client.clientList.GET_ALL_CLIENTS,
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getAllClientsSuccess', payload: response.data });

      handleClientListSuccessCallback && handleClientListSuccessCallback(response.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getAllClientsFailed', payload: error });

      handleClientListFailedCallback && handleClientListFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Handle Request Find Client Detail by Id
 * @param {object} requestBody
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns void
 */
export const requestClientDetail =
  (requestBody, handleRequestSuccessCallback = null, handleRequestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getClientByIdRequest' });

    const requestData = {
      url: API_URL.client.clientList.GET_FIND_CLIENT_BY_CLIENT_ID.replace(
        '{clientId}',
        requestBody?.clientId
      ),
      method: 'GET',
    };
    const successCallback = (response) => {
      handleRequestSuccessCallback && handleRequestSuccessCallback(response);
      dispatch({ type: 'getClientByIdRequestSuccess', payload: response.data });
    };
    const errorCallback = (error) => {
      handleRequestFailedCallback && handleRequestFailedCallback(error);
      dispatch({ type: 'getClientByIdRequestFailed', payload: error });
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Update Patient Profile Detail
 * @param {object} clientUpdaterRequestData {requestBody, clientDetail}
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns
 */
export const requestUpdateClient =
  (
    clientUpdaterRequestData,
    handleRequestSuccessCallback = null,
    handleRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'patchUpdateClientRequest' });
    let { requestBody, clientId } = clientUpdaterRequestData;

    const requestData = {
      url: API_URL.client.clientList.PATCH_UPDATE_CLIENT_PROFILE_BY_ID.replace(
        '{clientId}',
        clientId
      ),
      method: 'PATCH',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({
        type: 'patchUpdateClientRequestSuccess',
        payload: response.data,
      });
      const successMessage = `Client is Updated Successfully !`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleRequestSuccessCallback && handleRequestSuccessCallback();
    };
    const errorCallback = (error) => {
      dispatch({ type: 'patchUpdateClientRequestFailed', payload: error });
      let message = error?.response?.data?.name || 'Client Update is Failed !';

      Store.dispatch(handleNotification(message, 'error'));
      handleRequestFailedCallback && handleRequestFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Request Client List
 * @param {Function} handleClientListFilterSuccessCallback
 * @param {Function} handleClientListFilterFailedCallback
 * @returns
 */
export const requestFilterClientList =
  (filterData, handleClientListFilterSuccessCallback, handleClientListFilterFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postFilterClientsRequest' });

    const requestData = {
      url: API_URL.client.clientList.POST_FIND_FILTERED_CLIENTS,
      method: 'POST',
      data: filterData,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postFilterClientsRequestSuccess', payload: response.data });

      handleClientListFilterSuccessCallback && handleClientListFilterSuccessCallback(response.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postFilterClientsRequestFailed', payload: error });

      handleClientListFilterFailedCallback && handleClientListFilterFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Save Clients Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestSaveMultipleClients =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveMultipleClientsRequest' });

    const requestData = {
      url: API_URL.client.clientList.POST_CREATE_NEW_MULTIPLE_CLIENTS,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveMultipleClientsSuccess', payload: response.data });
      const successMessage = `Clients have been saved successfully.`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveMultipleClientsFailed', payload: error });
      let message = error?.response?.data?.name || 'Clients&apos; Save has Failed';
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * delete Clients Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestDeleteClient =
  (clientId, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteClientsRequest' });

    const requestData = {
      url: API_URL.client.clientList.DELETE_CLIENT_BY_ID.replace('{clientId}', clientId),
      method: 'DELETE',
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteClientsSuccess', payload: response.data });
      const successMessage = `Clients have been saved successfully.`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteClientsFailed', payload: error });
      let message = 'Client deletion has failed.';
      Store.dispatch(handleNotification(message, 'error'));
      handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
