import EventIcon from '@mui/icons-material/Task';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { requestGetAllTasks } from 'redux/Actions/tasksAction';
import MainCard from 'ui-component/cards/MainCard';
import NoData from 'ui-component/NoData';
import { Grid } from '@mui/material';

export default function TaskList() {
  const dispatch = useDispatch();
  const { tasksListData } = useSelector((state) => state.tasksReducer);
  useEffect(() => {
    dispatch(requestGetAllTasks());
  }, [dispatch]);
  const navigate = useNavigate();

  const [checked, setChecked] = React.useState([1]);

  // Ensure tasksListData is an array before slicing
  const taskList = Array.isArray(tasksListData) ? tasksListData.slice(0, 5) : [];

  console.log('*-*-*-*-*-*-taskList*-*-*-*-*-*-', taskList);
  console.log('*-*-*-*-*-*-taskList.tittle*-*-*-*-*-*-', taskList.tittle);

  return (
    <MainCard
      sx={{
        cursor: 'pointer',
        borderRadius: '16px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(135deg, #f5f5f5, #ffffff)',
        transition: 'all 0.3s ease',
        padding: '20px',
        border: '1px solid #e0e0e0',
        overflow: 'hidden',
        ':hover': {
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
          transform: 'scale(1.02)',
        },
      }}
      onClick={() => navigate('/main/task')}
    >
      <Typography
        sx={(theme) => ({
          color: theme.palette.blue?.[600],
          fontWeight: theme.typography.fontWeightBold,
          fontSize: '1.2rem',
          mb: 2,
        })}
      >
        Task List
      </Typography>
      {taskList.length > 0 ? (
        <List
          sx={{
            padding: 0,
            borderRadius: '12px',
            transition: 'all 0.3s ease',
          }}
        >
          {taskList.map((value) => {
            return (
              <ListItem
                key={value}
                disablePadding
                sx={{
                  marginBottom: '6px', // Space between ListItems
                }}
              >
                <ListItemButton
                  sx={(theme) => ({
                    borderRadius: '8px',
                    marginBottom: '8px', // Space between list items
                    padding: '10px 16px', // Padding inside the button
                    transition: 'background-color 0.3s',
                    background: 'linear-gradient(135deg, #f5f5f5, #f0f0f0)',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Soft shadow for each item
                    display: 'flex', // Enable flexbox for alignment
                    justifyContent: 'space-between', // Space between event name and date
                    alignItems: 'center', // Center the items vertically
                    '&:hover': {
                      backgroundColor: theme.palette.white,
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Elevate shadow on hover
                    },
                  })}
                >
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>
                  {/* Event Name on the Left Side */}
                  <ListItemText
                    primary={`${value.title.slice(0, 8)}...`}
                    primaryTypographyProps={{
                      sx: {
                        color: 'black !important', // Dark gray color for text
                        fontSize: '1rem', // Adjust font size
                      },
                    }}
                    sx={{ flex: 1 }} // Ensures event name takes available space
                  />

                  {/* Event Start Date on the Right Side */}
                  <Chip
                    label={value.priority}
                    variant="outlined"
                    sx={{
                      width: 75,
                      textAlign: 'center',
                      fontWeight: 100, // Set to normal weight
                      color:
                        value.priority === 'Low'
                          ? '#3ACF65'
                          : value.priority === 'Medium'
                          ? '#365DCF'
                          : value.priority === 'High'
                          ? '#CF3645'
                          : 'default',
                      borderColor:
                        value.priority === 'Low'
                          ? '#3ACF65'
                          : value.priority === 'Medium'
                          ? '#365DCF'
                          : value.priority === 'High'
                          ? '#CF3645'
                          : 'default',
                      backgroundColor: 'transparent',
                      borderWidth: 1,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Grid width="100%" height={300}>
          <NoData height={'12rem'} />
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ padding: '20px', paddingTop: '80px' }} // Adjust '40px' to your desired top padding value
          >
            No Data, Add New Event here !
          </Typography>
        </Grid>
      )}
    </MainCard>
  );
}