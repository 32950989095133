import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { getCashflow } from 'redux/Actions/cashflowAction';
import MainCard from 'ui-component/cards/MainCard';
import NoData from 'ui-component/NoData';

const Cashflow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cashflowReducer = useSelector((state) => state.cashflowReducer);
  const cashflowData = cashflowReducer?.getListData;
  const activeFinancialPosition = useSelector(
    (state) => state.authenticationReducer?.loginData?.activeFinancialPosition
  );

  const state = {
    financialPositionId: activeFinancialPosition,
  };

  const handleGetDataRequest = () => {
    const successCallback = () => {};
    const errorCallback = () => {};

    const requestAllData = {
      financialPositionId: activeFinancialPosition,
    };

    dispatch(getCashflow(requestAllData, successCallback, errorCallback));
  };

  let totalCashIn = 0;
  let totalCashOut = 0;

  cashflowData?.cashflow?.forEach((item) => {
    const amount = parseFloat(item.amount);
    if (item.type === 'Cash In') {
      totalCashIn += amount;
    } else if (item.type === 'Cash Out') {
      totalCashOut += amount;
    }
  });
console.log('--------------cashflowData?.cashflow?',  cashflowData?.cashflow);
  const dataToRender = [
    {
      name: 'Total Cash In',
      value: totalCashIn,
      fill: '#00C49F',
    },
    {
      name: 'Total Cash Out',
      value: totalCashOut,
      fill: '#FF66A6',
    },
  ];

  React.useEffect(() => {
    handleGetDataRequest();
  }, []);

  return (
    <MainCard
      sx={{
        cursor: 'pointer',
        borderRadius: '16px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(135deg, #f5f5f5, #ffffff)',
        transition: 'all 0.3s ease',
        padding: '20px',
        border: '1px solid #e0e0e0',
        overflow: 'hidden',
        ':hover': {
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
          transform: 'scale(1.02)',
        },
      }}
      onClick={() => navigate('/main/cashflow', { state: state })}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            sx={(theme) => ({
              color: theme.palette.blue?.[600],
              fontWeight: theme.typography.fontWeightBold,
              fontSize: '1.2rem',
              mb: 2,
            })}
          >
            Cashflow Overview
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {cashflowData?.cashflow.length ? (
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={dataToRender}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={100}
                  fill="#8884d8"
                  label
                >
                  {dataToRender.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.fill} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <ResponsiveContainer width="100%" height={300}>
              <NoData height={'12rem'} />
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                sx={{ padding: '20px', paddingTop: '40px' }}
              >
                No Data, Add New Cashflow here!
              </Typography>
            </ResponsiveContainer>
          )}
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default Cashflow;
