import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Save Task Action Request
 * @param {object} requestBody
 * @returns {async function}
 */
export const requestSaveTask =
  (requestBody, handleSaveTaskSuccessCallback, handleSaveTaskFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSaveTaskRequest' });

    const requestData = {
      url: API_URL.tasks.POST_SAVE_TASKS,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSaveTaskSuccess', payload: response });
      const successMessage = `Task is Created Successfully !`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleSaveTaskSuccessCallback && handleSaveTaskSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSaveTaskFailed', payload: error });
      let message = 'Task Save is Failed !';

      Store.dispatch(handleNotification(message, 'error'));
      handleSaveTaskFailedCallback && handleSaveTaskFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Get All Tasks
 * @param {object} requestBody
 * @param {Function} handleGetTasksFailedCallback
 * @returns
 */
export const requestGetAllTasks =
  (handleGetTasksSuccessCallback = null, handleGetTasksFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getAllTasksRequest' });

    const requestData = {
      url: API_URL.tasks.GET_ALL_TASKS,
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getAllTasksRequestSuccess', payload: response?.data });
      handleGetTasksSuccessCallback?.(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getAllTasksRequestFailed', payload: error });
      handleGetTasksFailedCallback && handleGetTasksFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Request Task Update
 * @param {*} updateRequestData
 * @param {*} handleTaskUpdateSuccessCallback
 * @param {*} handleTaskUpdateFailedCallback
 * @returns
 */
export const requestUpdateTask =
  (updateRequestData, handleTaskUpdateSuccessCallback, handleTaskUpdateFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'putTaskUpdateRequest' });
    const [requestBody, taskId] = [updateRequestData?.requestBody, updateRequestData?.taskId];
    const requestData = {
      url: API_URL.tasks.PUT_UPDATE_TASK.replace('{taskId}', taskId),
      method: 'PATCH',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'putTaskUpdateSuccess', payload: response.data });
      const successMessage = `Task is Updated Successfully !`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleTaskUpdateSuccessCallback && handleTaskUpdateSuccessCallback();
    };
    const errorCallback = (error) => {
      dispatch({ type: 'putTaskUpdateFailed', payload: error });
      let message = 'Task Update is Failed !';

      Store.dispatch(handleNotification(message, 'error'));
      handleTaskUpdateFailedCallback && handleTaskUpdateFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Find Task by id
 * @param {object} requestBody
 * @param {Function} handleGetTasksFailedCallback
 * @returns
 */
export const requestFindTaskById =
  (taskDetail, handleFindTaskSuccessCallback = null, handleFindTaskFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getFindTaskRequest' });
    const requestData = {
      url: API_URL.tasks.GET_FIND_TASK_BY_ID.replace('{taskId}', taskDetail?.taskId),
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getFindTaskRequestSuccess', payload: response.data });
      handleFindTaskSuccessCallback && handleFindTaskSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getFindTaskRequestFailed', payload: error });
      handleFindTaskFailedCallback && handleFindTaskFailedCallback(error);
    };
    (taskDetail?.taskId && http_request(requestData, successCallback, errorCallback)) ||
      dispatch({ type: 'getFindTaskRequestFailed', payload: { message: 'Task ID not Found !' } });
  };

/**
 * Handle Delete task request
 * @param {*} taskDetail
 * @param {*} handleDeleteSuccessCallback
 * @param {*} handleDeleteFailedCallback
 * @returns
 */
export const requestDeleteTask =
  (taskDetail, handleDeleteSuccessCallback = null, handleDeleteFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteTaskRequest' });

    const taskId = taskDetail?._id;

    const requestData = {
      url: API_URL.tasks.DELETE_TASK_BY_TASK_ID.replace('{taskId}', taskId),
      method: 'DELETE',
    };

    const successCallback = (response) => {
      dispatch({ type: 'deleteTaskRequestSuccess', payload: response.data });
      const successMessage = `Task is Deleted Successfully !`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleDeleteSuccessCallback && handleDeleteSuccessCallback();
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteTaskRequestFailed', payload: error });
      let message = 'Task Delete is Failed !';

      Store.dispatch(handleNotification(message, 'error'));
      handleDeleteFailedCallback && handleDeleteFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };

/**
 * Search tasks by filters
 * @param {*} searchRequestData
 * @param {*} handleGetTasksSuccessCallback
 * @param {*} handleGetTasksFailedCallback
 * @returns
 */
export const requestSearchTasks =
  (searchRequestData, handleGetTasksSuccessCallback = null, handleGetTasksFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postSearchTasksRequest' });
    // {
    //   "priorities": ["High", "low"],
    //   "assignees": ["643e436fbe0db088db8e5da1"], //sample filters @filterRequestBody
    //   "taskStatus": "Close"
    //   }
    const { filterRequestBody } = searchRequestData;

    const requestData = {
      url: API_URL.tasks.POST_SEARCH_TASKS_BY_FILTERS,
      method: 'POST',
      data: filterRequestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postSearchTasksRequestSuccess', payload: response.data });
      handleGetTasksSuccessCallback && handleGetTasksSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postSearchTasksRequestFailed', payload: error });
      handleGetTasksFailedCallback && handleGetTasksFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Handle request Tasks Assignees
 * @param {*} handleGetTaskAssigneesSuccessCallback
 * @param {*} handleGetTaskAssigneesFailedCallback
 * @returns
 */
export const requestTaskAssignees =
  (handleGetTaskAssigneesSuccessCallback = null, handleGetTaskAssigneesFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getTasAssigneesRequest' });

    const requestData = {
      url: API_URL.tasks.GET_ALL_TASK_ASSIGNEES,
      method: 'GET',
    };

    const successCallback = (response) => {
      dispatch({ type: 'getTasAssigneesRequestSuccess', payload: response?.data });
      handleGetTaskAssigneesSuccessCallback &&
        handleGetTaskAssigneesSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getTasAssigneesRequestFailed', payload: error });
      handleGetTaskAssigneesFailedCallback && handleGetTaskAssigneesFailedCallback(error);
    };

    http_request(requestData, successCallback, errorCallback);
  };
